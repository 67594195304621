.page-container{
    display: flex;
    background-size: contain;
    position: relative;
    background-repeat: no-repeat;
}

.page-horizontal{
    flex-direction: row;
    justify-content: space-between;
}