.book-16-9{
    /* display: block; */
    /* width: 94vw; */
    /* position: relative; */
    z-index: 1;
    /* margin: 0 auto; */
}

/* .book-16-9, .book-16-9 .page-container{
    height: 52.875vw;
} */

/* Slider */

button.slick-arrow{
    position: absolute;
    z-index: 20;
    top: 50%;

}

button.slick-prev{
    left: 1%;
}

button.slick-next{
    right: 1%;
}