.vertical-1{
    display: flex;
    width: 33%;
    height: 100%;
}

.vertical-2{
    display: flex;
    width: 66%;
    height: 100%;
}

.vertical-1_5{
    display: flex;
    width: 50%;
    height: 100%;
}

.vertical-3{
    display: flex;
    width: 100%;
    height: 100%;
}

.text > p{
    padding: 2%;
}

.atop{
    align-items: flex-start;
}

.amiddle{
    align-items: center;
}

.abottom{
    align-items: flex-end;
}

.text{
    font-size: var(--book-font-size);
    line-height: var(--book-line-height);
}