/* Fonts */
@import url("https://use.typekit.net/obq3mxj.css");

:root{
    --amarillo: #FFDD00;
}

html, body {
    margin: 0; 
    height: 100%; 
    overflow: hidden
}

body{
    color:#333;
}

p{
    font-family: "quicksand", sans-serif;
    font-weight: 400;
    font-style: normal;
}

h1, h2, h3, h4{
    font-family: "grenadine-mvb", sans-serif;
    font-weight: 500;
    font-style: normal;
}

/* .main-container{
    padding-left: 3vw;
    padding-right: 3vw;
} */

/* Estilo de textos */
/* .text{
    font-size: 2.5vw;
    line-height: 3vw;
} */

/* Folio */
.page-number{
    background-color: var(--amarillo);
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    font-size: var(--book-font-size)
}
.page-number > span{
    font-family: "grenadine-mvb", sans-serif;
    font-weight: 500;
}

/* Botones */
.slick-arrow{
    font-size: 0;
    color: transparent;
    outline: none;
    background: var(--amarillo);
    border: 0;
    top: 0 !important;
    height: 100%;
    width: calc(var(--book-font-size) * 2.5);
}

.slick-disabled{
    filter: grayscale();
}

.slick-next{
    right: calc(var(--book-font-size) * -2.5) !important;
    padding-left: calc(var(--book-font-size) * 0.5);

}

.slick-prev{
    left: calc(var(--book-font-size) * -2.5) !important;
    padding-left: calc(var(--book-font-size) * 1.5);
}

.slick-next::after{
    display: block;
    content: '';
    width: 0; 
    height: 0; 
    border-top: calc(var(--book-font-size) * 0.5) solid transparent;
    border-bottom: calc(var(--book-font-size) * 0.5) solid transparent;
    border-left: calc(var(--book-font-size) * 0.5) solid #333;
}

.slick-prev::after{
    display: block;
    content: '';
    width: 0; 
    height: 0; 
    border-top: calc(var(--book-font-size) * 0.5) solid transparent;
    border-bottom: calc(var(--book-font-size) * 0.5) solid transparent;
    border-right: calc(var(--book-font-size) * 0.5) solid #333;
}

.slick-disabled::after{
    opacity: 0.3;
}

/* Boton audio */
.text-anim-container{
    position: relative;
    padding-left: calc(var(--book-font-size) * 1);
    padding-right: calc(var(--book-font-size) * 1);
}

.p-anim>span:first-child{
    margin-left: calc(var(--book-font-size) * 2.75);
}

.read-button{
    position: absolute;
    font-size: 0;
    width: calc(var(--book-font-size) * 2.5);
    height: calc(var(--book-font-size) * 1.3);
    background-color: var(--amarillo);
    border: 0;
    border-radius: calc(var(--book-font-size) * 2);
    margin-top: calc(var(--book-font-size) * 0.9);
}

/* Book Shelf */

.book-shelf .slick-arrow{
    top: -13vw !important;
}

.book-shelf .slick-next{
    right: -8vw !important;
}

.book-shelf .slick-prev{
    left: -8vw !important;  
}

.book-shelf .slick-list{
    overflow: visible;
}

.book-shelf .slick-slide{
    transform: scale(1);
    transition: transform ease 0.3s;
}

.book-shelf .slick-slide:hover{
    transform: scale(1.05);
    transition: transform ease 0.2s;
}

.book-cover{
    box-shadow: 0 5px 10px 4px rgba(0,0,0,0.10);
}