.page-number{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 2%;
    width: calc(var(--book-font-size) * 2);
    height: calc(var(--book-font-size) * 2);;
    z-index: 19;
}